jQuery(function($){       
    var $telMaskFunction = function (val) {
        return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
    },
    telOptions = {
        onKeyPress: function(val, e, field, options) {
            field.mask($telMaskFunction.apply({}, arguments), options);
        }
    };      
    $('.input-tel').mask($telMaskFunction, telOptions); 

    //manter session
    setInterval(function(){
        $.post('/ping.php');
    }, 60000);
});